<template>
  <div class="menubox">
    <!-- <div class="header">申请单</div> -->
    <div>
      <img style='float:left;margin:39px 0px 0px 30px;' src='../../assets/index/logo.svg'>
      <span style='float:left;color:#fff;font-size:20px;font-weight:600;margin:38px 0px 0px 10px;'>智慧试衣执行中台</span>
      <p style="font-size:16px;color:#fff;float:left;margin-left:20px;">独立控制版</p>
    </div>
    <ul style='margin-top:0px;float:left;'>
      <li    @click="goPage('/index', 'local')"
            class="relative cursor-pointer"
            :class="page == 'index' ? 'li_active' : ''" >
        <span
              class="bg absolute top-0 left-0"
              :class="page == 'index' ? 'bg_active' : ''"
            ><img style="width:23px;height:23px;margin-top:18px;margin-left:10px" src='../../assets/index/index.svg'></span>
            <span 
              class="text absolute top-0 left-0"
              :class="page == 'index' ? 'text_active' : ''"
              >首页</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'index' ? 'sanjiao_active' : ''"
            ></span>
      </li>
      <li    @click="analy()"
            class="relative cursor-pointer"
            :class="analyd == true ? 'li_active' : ''" >
        <span
              class="bg absolute top-0 left-0"
              :class="analyd == true ? 'bg_active' : ''"
            ><img style="width:23px;height:23px;margin-top:18px;margin-left:10px" src='../../assets/index/data.svg'></span>
            <span
              class="text absolute top-0 left-0"
              :class="analyd == true ? 'text_active' : ''"
              >设备管理</span
            >
            <span
              class="sanjiao absolute"
              :class="analyd == true ? 'sanjiao_activex' : 'sanjiao_active'"
            ></span>
      </li> 
       <li style="height:100px;" v-if = 'analyd'>
        <ul id="myul" style='margin-top:0px;float:left;padding:0px;'>
          <li
          @click="goPage('/model', 'local')"
            class="relative cursor-pointer"
            :class="page == 'localAnanlyze' ? 'li_active' : ''"
          >
            <span
              class="bg absolute top-0 left-0"
              :class="page == 'localAnanlyze' ? 'bg_active' : ''"
            ><img src=''></span>
            <span
              class="texta absolute top-0 left-0"
              :class="page == 'localAnanlyze' ? 'text_active' : ''"
              >模型管理</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'localAnanlyze' ? 'sanjiao_active' : ''"
            ></span>
          </li>
          <li
          @click="goPage('/robot', 'local')"
            class="relative cursor-pointer"
            :class="page == 'webAnanlyze' ? 'li_active' : ''"
          >
            <span
              class="bg absolute top-0 left-0"
              :class="page == 'webAnanlyze' ? 'bg_active' : ''"
            ></span>
            <span
              class="texta absolute top-0 left-0"
              :class="page == 'webAnanlyze' ? 'text_active' : ''"
              >机器人管理</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'webAnanlyze' ? 'sanjiao_active' : ''"
            ></span>
          </li>
        </ul>
      </li> 
      <!-- <li    @click="goPage('/content', 'local')"
            class="relative cursor-pointer"
            :class="page == 'content' ? 'li_active' : ''" >
        <span
              class="bg absolute top-0 left-0"
              :class="page == 'content' ? 'bg_active' : ''"
            ><img style="width:23px;height:23px;margin-top:18px;margin-left:10px" src='../../assets/index/content.svg'></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'content' ? 'text_active' : ''"
              >内容管理</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'content' ? 'sanjiao_active' : ''"
            ></span>
      </li>
      <li    @click="goPage('/tags', 'local')"
            class="relative cursor-pointer"
            :class="page == 'tags' ? 'li_active' : ''" >
        <span
              class="bg absolute top-0 left-0"
              :class="page == 'tags' ? 'bg_active' : ''"
            ><img style="width:23px;height:23px;margin-top:18px;margin-left:10px" src='../../assets/index/tags.svg'></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'tags' ? 'text_active' : ''"
              >标签管理</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'tags' ? 'sanjiao_active' : ''"
            ></span>
      </li>
      <li    @click="goPage('/robot', 'local')"
            class="relative cursor-pointer"
            :class="page == 'robot' ? 'li_active' : ''" >
        <span
              class="bg absolute top-0 left-0"
              :class="page == 'robot' ? 'bg_active' : ''"
            ><img style="width:23px;height:23px;margin-top:18px;margin-left:10px" src='../../assets/index/robot.svg'></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'robot' ? 'text_active' : ''"
              >机器人管理</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'robot' ? 'sanjiao_active' : ''"
            ></span>
      </li> -->
      <li    @click="goPage('/client', 'local')"
            class="relative cursor-pointer"
            :class="page == 'client' ? 'li_active' : ''" >
        <span
              class="bg absolute top-0 left-0"
              :class="page == 'client' ? 'bg_active' : ''"
            ><img style="width:23px;height:23px;margin-top:18px;margin-left:10px" src='../../assets/index/custom.svg'></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'client' ? 'text_active' : ''"
              >客户管理</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'client' ? 'sanjiao_active' : ''"
            ></span>
      </li> 
    </ul>
        <!-- <ul style='margin-top:82px;float:left;'>
          <li
            @click="goPage('/localAnanlyze', 'local')"
            class="relative cursor-pointer"
            :class="page == 'localAnanlyze' ? 'li_active' : ''"
          >
            <span
              class="bg absolute top-0 left-0"
              :class="page == 'localAnanlyze' ? 'bg_active' : ''"
            ><img src=''></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'localAnanlyze' ? 'text_active' : ''"
              >本地数据申请分析</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'localAnanlyze' ? 'sanjiao_active' : ''"
            ></span>
          </li>
          <li
            @click="goPage('/webAnanlyze', 'web')"
            class="relative cursor-pointer"
            :class="page == 'webAnanlyze' ? 'li_active' : ''"
          >
            <span
              class="bg absolute top-0 left-0"
              :class="page == 'webAnanlyze' ? 'bg_active' : ''"
            ></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'webAnanlyze' ? 'text_active' : ''"
              >网页申请数据分析</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'webAnanlyze' ? 'sanjiao_active' : ''"
            ></span>
          </li>
          <li
            @click="goPage('/monthAnanlyze', 'month')"
            class="relative cursor-pointer"
            :class="page == 'monthAnanlyze' ? 'li_active' : ''"
          >
            <span
              class="bg absolute top-0 left-0"
              :class="page == 'monthAnanlyze' ? 'bg_active' : ''"
            ></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'monthAnanlyze' ? 'text_active' : ''"
              >本月申请数据分析</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'monthAnanlyze' ? 'sanjiao_active' : ''"
            ></span>
          </li>
          <li
            @click="goPage('/yearAnanlyze', 'year')"
            class="relative cursor-pointer"
            :class="page == 'yearAnanlyze' ? 'li_active' : ''"
          >
            <span
              class="bg absolute top-0 left-0"
              :class="page == 'yearAnanlyze' ? 'bg_active' : ''"
            ></span>
            <span
              class="text absolute top-0 left-0"
              :class="page == 'yearAnanlyze' ? 'text_active' : ''"
              >本年申请数据分析</span
            >
            <span
              class="sanjiao absolute"
              :class="page == 'yearAnanlyze' ? 'sanjiao_active' : ''"
            ></span>
          </li>
        </ul> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: "",
      analyd:false
    };
  },
  watch: {
    $route: function(newVal, oldVal) {
      this.page = newVal.name;
    },
  },
  methods: {
    analy(){
      this.analyd = !this.analyd
      this.page = ''
    },
    goPage(path, type) {
      this.analyd = false
      //跳转时页面
      this.$router.push({
        path,
        query: {
          type,
        },
      });
    },

    goPage1(path, type) {
      //this.analyd = false
      //跳转时页面
      this.$router.push({
        path,
        query: {
          type,
        },
      });
    },

  },
};
</script>

<style lang="less" scoped>
.texta{font-size:14px;margin-left:40px;}
.menubox {
  
      background: url(../../assets/left.png) no-repeat 100%;height:900px;
  background-size: 100%;
}
.header {
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #333333;
  background: @bg-color !important;
  padding-left: 20px;
  font-size: 16px;
}
#myul li{margin-left:50px;height:40px;font-size:14px;line-height:40px;}
ul {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  li {
    font-weight: bold;
    text-align: left;
    display: inline-block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color:#fff;font-size:17px;
    border-radius: 10px;
    
   
    overflow: hidden;
    span {
      display: inline-block;
    }
    .bg {
      width: 92%;
      height: 100%;
      border-radius: 10px;
    }
    .bg_active {
      width: 92%;
      height: 100%;
      background: #4161EE;
      border-radius: 10px;
    }
    .text {
      width: 100%;
      height: 100%;
      margin-left:50px
    }
    .text_active {
      color: white;
    }
    .sanjiao{position: absolute;right:10px;top:25px;}
    .sanjiao:after {
content: " ";
    display: inline-block;
    height: 10px;
    width: 10px;
    border-width: 2px 2px 0px 0;
    border-color: #fff;
    border-style: solid;
    -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;right:0px;
}
    .sanjiao_active {
      position: absolute;right:10px;top:25px;

    }
    .sanjiao_activex {
      position: absolute;right:10px;top:25px;

    }
    .sanjiao_activex:after {
      content: " ";
    display: inline-block;
    height: 10px;
    width: 10px;
    border-width: 0px 2px 2px 0;
    border-color: #fff;
    border-style: solid;
    -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      position: absolute;

    }
  }
  .li_active{
      background: #4161EE;
  }
}
</style>

<template>
  <a-config-provider style='width:100%;min-width:1920px;max-width:1920px;height:900px;margin:0 auto;' :locale="locale">
    <a-layout id="components-layout-demo-top-side-2">
      <!-- <a-layout-header class="app_header">智慧试衣订单服务执行中台</a-layout-header> -->
      <div style="background-color:#f6f9fe" class=" justify-around  app_content">
        <div style='width:280px;float:left;height:900x;' class="app_aside menu_bar">
          <menu-bar></menu-bar>
        </div>
        <a-layout style="padding:0px 00px;width:1620px;height:900px;float:left;" class="app_content_box">
          <a-layout-content
            class="relative"
            :style="{
              padding: '0px',
              margin: 0,
              minHeight: '280px',
            }"
          >
                <!-- <div @click="goToPage('discountCoupon')" class="bgbai" style="margin:30px 10px 0px 30px;background-color:#fff;padding:30px 0px 0px 47px;width:360px;float:left;text-align:center;height:80px;border-radius:20px;line-height:100px">
                <img style="width:25px;height:22px;float:left" src="../assets/index/add.svg">
                  <span style="margin-top:-42px;margin-left:15px;float:left;font-size:20px;color:#6B798C">添加优惠券</span>
                <img style="width:14px;height:14px;float:right;margin-right:94px;" src="../assets/index/addjia.svg">
               </div> -->
      <div class="bgbai" style="margin:30px 0px 10px 30px;background-color:#fff;padding:30px 0px 0px 47px;float:left;width:360px;text-align:center;height:80px;border-radius:20px;line-height:100px">
                <img style="width:25px;height:22px;float:left" src="../assets/index/time.svg">
                  <span style="margin-top:-42px;margin-left:15px;float:left;font-size:20px;color:#6B798C">{{ nowTime }}</span>
               
      </div>
      <div v-if='message_show' style='z-index:99;border-radius:30px;position:absolute;right:250px;top:80px;padding:20px;width:360px;height:330px;background-color:#fff'>
        <p style='font-size:15px;color:#353d49;line-height:20px;font-weight:550'>新消息</p>
        
        <ul>
          <li v-for="item in list" :key="item.id" style='background-color:#f6f9fe;border-radius:10px;margin-top:10px;height:64px;'>
            <img style='margin:10px 10px 0px 10px;width:39px;height:39px;float:left;' src='../assets/girl.png'>
            <p style='margin:6px auto;width:200px;float:left;font-weight:600;'>{{ item.nickname }}<p>
            <p style='margin:0px auto;width:200px;float:left;'>{{ ssssub(item.content) }}</p>
            <span></span>
          </li>
          </ul>

        <p  @click="goToPage('information')" style='text-align:center;color:#294DEA'>查看更多<img style='display:inline;margin:-2px 0px 0px 3px;width:6px;height:12px;' src='../assets/rightarr.png'></p>
      </div>
      <div style='float:right;height:80px;width:300px;padding-top:20px;'>
          <img @click="zhanshi()"   style="width:27px;height:33px;float:left;margin-top:10px;" src="../assets/index/message.svg"> 
         <img @click="goToPage('user')" style="width:60px;height:60px;float:left;margin-left:20px;" src="../assets/girl.png">
         <span @click="goToPage('user')" style='float:left;font-size:16px;line-height:60px;margin-left:20px;'>admin</span>
         <img style="width:8px;height:16px;float:left;margin:25px 0px 0px 20px;" src="../assets/index/rightarr.svg">
      </div>
        <div style='width:100%;float:left;'>
              <div style='font-size:15px;float:left;' class="pr-4 cursor-pointer " @click="goBack">
                <!-- <img style="width:8px;height:16px;float:left;margin:0px 0px 0px 20px;" src="../assets/index/leftarr.svg"> -->
                &nbsp; &nbsp;
                返回
              </div>

              <a-breadcrumb>
                <a-breadcrumb-item>
                  <span @click="goHome" class="cursor-pointer">首页</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item><span style="color:#294dea">{{ path }}</span></a-breadcrumb-item>
              </a-breadcrumb>
        </div>      
            <!-- <div class="absolute header_menu_box">
              <header-menu></header-menu>
            </div> -->
            <!-- <div
              class="flex justify-between px-4 align-center app_content_header bg-lightish"
            >

              <div>
                操作员：
                <span class="text-primary">{{
                  userInfo.authInfo ? userInfo.authInfo.name : ""
                }}</span>
              </div>
              <div>
                今日工作负荷占比
                <span class="pl-2 text-primary">85%</span>
              </div>
            </div>
            <div
              :style="{
                background: '#fff',
              }"
              class="flex items-center px-4 pt-4"
              v-if="path != ''"
            >
              <div class="pr-4 cursor-pointer text-primary" @click="goBack">
                返回
              </div>

              <a-breadcrumb>
                <a-breadcrumb-item>
                  <span @click="goHome" class="cursor-pointer">首页</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item>{{ path }}</a-breadcrumb-item>
              </a-breadcrumb>
            </div> -->

            <div
              :style="{
                background: '#f6f7fe',
              }"
              style="width:100%;float:left;height:720px;overflow-y:scroll;"
            >
              <router-view></router-view>
            </div>
          </a-layout-content>
        </a-layout>
 
      </div>
             <div class="right_all">
				©2016-2022望墨科技(武汉)有限公司 All Rights Reserved
		</div>
    </a-layout>
  </a-config-provider>

</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import MenuBar from "components/menubar/menubar";
import SideBar from "components/sidebar/sidebar";
import { commentList, commentRead, reply } from "@/api/notify";
import HeaderMenu from "components/headerMenu/headerMenu";
import { timeout } from "q";
export default {
  data() {
    return {
      message_show:false,
      locale: zhCN,
      list:[],
      collapsed: false,
      path: "",
      pathObj: {
        index: "",
        information: "留言",
        robot: "机器人管理",
        user: "基础设置",
        confirmation: "确认码",
        client: "客户管理",
        uploadFile: "文件上传",
        stop: "紧急停止",
        discountCoupon: "生成优惠券",
        todayPlan: "今日采集计划",
        tomorrowPlan: "明日采集计划",
        collectWork: "采集工作",
        localCollect: "本地采集",
        localAnanlyze: "本地申请数据分析",
        webAnanlyze: "网页申请数据分析",
        monthAnanlyze: "本月申请数据分析",
        yearAnanlyze: "本年申请数据分析",
        edit: "数据录入",
        tags: "标签管理",
        content: "内容管理",
        contentEdit: "内容编辑",
      },
      userInfo: {},
      nowTime: "",
      timer: null,
    };
  },
  components: {
    MenuBar,
    SideBar,
    HeaderMenu,
  },
  created() {
    let path = localStorage.getItem("path");
    if (path) {
      this.path = this.pathObj[path];
    }
    this.aquireMessageList()
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userInfo = Object.assign({}, userInfo);
    clearInterval(this.timer);
    this.timer = null;
    this.getTime();
    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  watch: {
    $route: function(newVal, oldVal) {
      document.documentElement.scrollTop = 0;
      let path = newVal.name;
      this.path = this.pathObj[path];
      localStorage.setItem("path", path);
    },
  },
  methods: {
    ssssub(str){
      return str.slice(0,33)
    },
    aquireMessageList() {
      commentList({
        page: 0,
        limit: 3,
      })
        .then((res) => {
          console.log("获取留言", res);
          if (res.code == 200) {
            this.list = [];
            this.list.push(...res.data.list);
            this.total = res.data.count;
            // this.unReadList = [];
            // res.data.list.forEach((item) => {
            //   if (item.status == 0) {
            //     this.unReadList.push(item.id);
            //   }
            // });
            // if (this.unReadList.length > 0) {
            //   this.commentReadFunc();
            // }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    zhanshi(){
      this.message_show = !this.message_show
    },
    yincang(){
      this.message_show = false
    },
       goToPage(pagePath) {
      this.$router.push({ path: pagePath });
    },
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({
        path: "/index",
      });
    },
    getTime() {
      this.nowTime = this.parseDate(new Date().getTime(), "full", "-");
    },
    // 处理时间
    parseDate(timestamp, type, text) {
      /**
       * @timetamp  时间戳
       * @type 类型
       * @text 拼接的字符串
       */
      let date = new Date(timestamp);
      let Y = "";
      let M = "";
      let D = "";
      if (type == "Chinese") {
        Y = date.getFullYear() + "年";
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "月";
        D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
        return M + D;
      }
      Y = date.getFullYear() + text;
      M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + text;
      const h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      const m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      const s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 秒
      if (type == "full") {
        D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        return Y + M + D + h + m + s;
      }
      if (type == "simple") {
        D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return Y + M + D;
      }

      if (type == "time") {
        return h + m + s;
      }
    },
  },
};
</script>

<style lang="less">
.right_all{text-align: center;
    font-size:1rem;
    position: fixed;
    width: 100%;
    color: #555;
    bottom: 11px;}
.app_header {
  background: @primary-color !important;
  height: 64px;
  display: flex;
  justify-content: flex-start;font-size:1.5rem;color:#eee;
  // padding: 0 !important;
}
// .app_content {
//   height: 100%;
// }
.app_content_header {
  height: 48px;
  div {
    height: 48px;
    line-height: 48px;
  }
}
.ant-layout-content {
  box-sizing: border-box;
}
// .app_header_box {
//   background: @primary-color !important;
//   min-width: 590px !important;
//   max-width: 1024px !important;
//   box-sizing: border-box !important;
// }
.app_content_box {
 
  
  box-sizing: border-box !important;
}
.app_aside {
  min-width: 200px !important;
  max-width: 300px !important;
}
.menu_bar {
  width: 300px;

}
.header_menu_box {
  width: 100%;
  top: -70px;
}
</style>
